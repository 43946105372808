import { RiFileSearchLine } from "react-icons/ri";
import { MdOutlineDashboard } from "react-icons/md";
import { TbNotes, TbBrandMiniprogram } from "react-icons/tb";
import FavoriteChart from "./../../assets/favorite-chart.svg";

const MENU_ITEMS = [
  {
    icon: <MdOutlineDashboard className="text-white w-[18px] h-[20px]" />,
    id: "fdb",
    label: "FDB",
    path: "#",
    child: [
      {
        icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
        id: "dashboard-fdb",
        label: "Dashboard",
        path: "/dashboard-fdb",
      },
      {
        icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
        id: "deptor",
        label: "Daftar Debitur",
        path: "/deptor",
      },
      {
        icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
        id: "group",
        label: "Daftar Kelompok",
        path: "/group",
      },
      {
        icon: <RiFileSearchLine className="text-white w-[18px] h-[20px]" />,
        id: "monitoring",
        label: "Monitoring",
        path: "/monitoring",
      },
      {
        id: "evaluate-planning",
        path: "/evaluate-planning",
        label: "Perencanaan",
      },
      // {
      //   id: "evaluate-organize",
      //   path: "/evaluate-organize",
      //   label: "Pengaturan Daftar Debitur",
      // },
      {
        id: "evaluate-paper-work",
        path: "/evaluate-paper-work",
        label: "Kertas Kerja",
      },
      {
        id: "evaluate-indicator",
        path: "/evaluate-indicator",
        label: "Indikator",
      },
      {
        id: "assignment",
        path: "/assignment",
        label: "Penugasan",
      },
    ],
  },
  {
    icon: <TbBrandMiniprogram className="text-white w-[18px] h-[20px]" />,
    id: "program",
    label: "Dana Program",
    path: "#",
    child: [
      {
        id: "program-dashboard",
        label: "Dashboard",
        path: "/program-dashboard",
      },
      {
        id: "program-physical-and-financial",
        path: "/program-physical-and-financial",
        label: "Fisik & Keuangan",
      },
      {
        id: "program-progress",
        path: "/program-progress",
        label: "Kemajuan",
      },
      {
        id: "program-list",
        path: "/program-list",
        label: "List Program",
      },
      {
        id: "program-investigate",
        path: "/investigate",
        label: "Investigasi"
      },
      {
        id: "on-desk",
        path: "/on-desk",
        label: "On Desk"
      },
      {
        id: "on-site",
        path: "/on-site",
        label: "On Site"
      },
      {
        id: "program-assignment",
        path: "/assignment-program",
        label: "Penugasan"
      }
    ],
  },
  // {
  //   icon: <img src={FavoriteChart} className="text-white w-[18px] h-[20px]" />,
  //   id: "evaluate",
  //   label: "Evaluasi",
  //   path: "#",
  //   child: [
  //     {
  //       id: "evaluate-planning",
  //       path: "/evaluate-planning",
  //       label: "Perencanaan",
  //     },
  //     {
  //       id: "evaluate-organize",
  //       path: "/evaluate-organize",
  //       label: "Pengaturan Daftar Debitur",
  //     },
  //     {
  //       id: "evaluate-paper-work",
  //       path: "/evaluate-paper-work",
  //       label: "Kertas Kerja",
  //     },
  //     {
  //       id: "evaluate-indicator",
  //       path: "/evaluate-indicator",
  //       label: "Indikator",
  //     },
  //   ],
  // },
  // {
  //   icon: <TbNotes className="text-white w-[18px] h-[20px]" />,
  //   id: "program",
  //   label: "Penugasan",
  //   path: "/assignment",
  // },
];

export default MENU_ITEMS;
