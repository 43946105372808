import Assignment from "pages/Assignment"

const assignmentRoutes = [
  {
    routeType: "public",
    path: "/assignment",
    element: Assignment,
    exact: true,
    type: "canView",
    key: "assignment",
  },
]

export default assignmentRoutes